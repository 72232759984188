.dobble-render {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dobble-card {
  position: relative;
  width: 70vw;
  height: 70vw;

  @media (max-width: 768px) {
    width: 90vw;
    height: 90vw;
  }

  @media (min-aspect-ratio: 8/7) {
    width: 80vh;
    height: 80vh;  
  }

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 20px 5px #00000055;
  border-radius: 50%;
}

.dobble-number {
  position: absolute;
  pointer-events: none;
  
  &:not(:nth-child(1)) {
    padding-top: 70%;
  }

  > img {
    height: 18vw;
    width: 18vw;
    pointer-events: all;
    cursor: pointer;
    animation: spin 120s linear infinite;

    @media (max-width: 768px) {
      width: 24vw;
      height: 24vw;
    }
  
    @media (min-aspect-ratio: 8/7) {
      width: 24vh;
      height: 24vh;  
    }
  
  }
}

@keyframes spin { 
  100% { 
      transform:rotate(360deg); 
  } 
}