.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo-text {
  font-size: x-large;
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: chocolate;
  font-weight: bold;
}

.logo-app {
  margin: 10px;
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shell {
  transition: background-color ease-in 2s;
 
  &.nudged {
    transition: background-color ease-out 0.2s;
    background-color: red;
  }

  .header-container {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
  }

}


.user-chip {
  border: 1px solid #c0c0c0;
  height: 30px;
  display: grid;
  grid-gap: 2px;
  padding: 2px;
  grid-template-columns: 1fr 30px;

  > div > span {
    padding-right: 10px;
  }
}